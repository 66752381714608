:root {
  --font-family-work-sans: 'Work Sans', sans-serif;
  --font-family-circle8: 'Circle8', sans-serif;
  --font-family-recife: 'RecifeText', serif;
  --font-family-avenir: 'Avenir', serif;
  --font-family-optima: 'Optima', serif;

  --font-weight-400: 400;
  --font-weight-600: 600;

  --line-height-xs: 0.9;
  --line-height-base: 1;
  --line-height-sm: 1.125;
  --line-height-md: 1.25;
  --line-height-lg: 1.5;

  --letter-spacing-xl: -0.05em;
  --letter-spacing-lg: -0.025em;
  --letter-spacing-md: -0.01em;
  --letter-spacing-sm: -0.005em;

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-25: 25px;
  --font-size-35: 35px;
  --font-size-42: 42px;
  --font-size-70: 70px;
  --font-size-75: 75px;
  --font-size-80: 80px;
  --font-size-90: 90px;
  --font-size-100: 100px;
  --font-size-200: 200px;
  --font-size-220: 220px;

  --font-size-16-12: clamp(16px, calc(16px - 4 * var(--size)), 12px);

  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--size)), 16px);
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--size)), 20px);
  --font-size-20-25: clamp(20px, calc(20px + 5 * var(--size)), 25px);
  --font-size-16-35: clamp(16px, calc(16px + 19 * var(--size)), 35px);
  --font-size-20-36: clamp(20px, calc(20px + 16 * var(--size)), 36px);
  --font-size-25-35: clamp(25px, calc(25px + 10 * var(--size)), 35px);
  --font-size-16-70: clamp(16px, calc(16px + 54 * var(--size)), 70px);
  --font-size-42-70: clamp(42px, calc(42px + 28 * var(--size)), 70px);
  --font-size-35-75: clamp(35px, calc(35px + 40 * var(--size)), 75px);
  --font-size-100-160: clamp(100px, calc(100px + 60 * var(--size)), 160px);
  --font-size-100-200: clamp(100px, calc(100px + 100 * var(--size)), 200px);
  --font-size-90-220: clamp(90px, calc(90px + 130 * var(--size)), 220px);

  --size-unit: 1px;
  --size-viewport-min: 480;
  --size-viewport-max: 1200;
  --size-growth: calc(1 / (var(--size-viewport-max) - var(--size-viewport-min)));
  --size: calc((var(--size-growth) * 100vw) - (var(--size-growth) * var(--size-viewport-min) * var(--size-unit)));
}
