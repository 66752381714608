.page {
  height: 100vh;
  background-color: var(--primary-background-color);
  padding: var(--size-30) 0;

  @media (--viewport-md) {
    padding: var(--size-50) 0;
  }

  & > .containerLayout {
    height: 100%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);

  @media (--viewport-md) {
    justify-content: space-between;
  }

  & > .logoLayout {
    width: max-content;
  }
}

.textContainer {
  & > .headingLayout {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      max-width: 70%;
    }
  }
}
