
.component {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1ch;
  position: relative;
}

.clippingContainer {
  display: inline-flex;
  padding-bottom: 0.2em;
  overflow: clip;
}
