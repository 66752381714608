.main {
  position: relative;
  background-color: var(--primary-background-color);
  z-index: 0;

  & > .imageWithQuoteCorporateLayout {
    margin-top: 100px;
    min-height: 75dvh;

    @media (--viewport-md) {
      margin-top: 200px;
    }
  }

  & > .contentLayout {
    margin-top: 100px;
    margin-bottom: 100px;

    @media (--viewport-md) {
      margin-bottom: 200px;
    }
  }

  & > .ourBrandsLayout {
    margin-top: 185px;
    margin-bottom: 100px;
  }

  & > .whatWeDoLayout {
    margin-top: 15px;
    margin-bottom: 100px;

    @media (--viewport-md) {
      margin-bottom: 200px;
    }
  }
}

.componentWhatWeDo {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-areas: 'content';
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-areas: 'logo content';
    grid-template-columns: 2fr 3fr;
  }

  & > .logo {
    z-index: -1;
    grid-area: logo;
  }

  & > .content {
    z-index: 0;
    grid-area: content;
  }
}

.content {
  & > *:not(:last-child) {
    margin-bottom: var(--size-30);
  }

  & > .headingText {
    width: 100%;
  }
}

.logo {
  display: none;
  position: relative;
  color: var(--primary-background-color);

  @media (--viewport-md) {
    display: block;
  }

  & > .logoLayout {
    position: absolute;
    right: 60px;
    top: -40px;
    width: 400px;
  }
}

.headingText {
  & > .textLayout {
    &:not(:first-child) {
      margin-top: var(--size-5);
    }
  }
}

.componentHero {
  padding-top: 125px;
  padding-bottom: 100px;

  @media (--viewport-md) {
    padding-top: 200px;
  }

  & > .headingLayout {
    margin-bottom: var(--size-30);
  }
}

.componentContactTable,
.componentContact,
.componentAddress {
  list-style: none;
  padding: 0;
}

.componentContactTable {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
  padding-bottom: 100px;
}

.componentContact {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);
}

.componentCompany {
  display: flex;
  gap: var(--size-20);
  flex-direction: column;
  scroll-margin: 100px;

  & > .logoLayout {
    max-width: 200px;
  }
}

.componentAddress {
  display: flex;
  gap: var(--size-8);
}

.address {
  display: flex;
  flex-direction: column;
}

.componentOffices {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: var(--size-50);

  @media (--viewport-md) {
    gap: var(--size-20);
  }
}

.office {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-10);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.layout {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--size-20);

  @media (--viewport-md) {
    grid-template-columns: 2fr 1fr;
  }

  &:has(.title) {
    grid-template-rows: auto 1fr;
  }

  & > .title {
    @media (--viewport-md) {
      grid-column: span 2;
    }
  }
}

.contactLink {
  color: var(--primary-color);
  display: flex;
  gap: var(--size-10);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: var(--color-blue-300);
  }

  & > .iconLayout {
    width: 22px;
    height: 22px;
  }
}

.heading {
  font-size: var(--font-size-16-12);
  font-family: var(--font-family-avenir);
  font-weight: var(--font-weight-600);
  color: var(--primary-color);
}

.title {
  font-size: var(--font-size-16-20);
  font-family: var(--font-family-avenir);
  color: var(--primary-color);
  font-weight: var(--font-weight-600);
}
