.component {
  display: inline-flex;
  gap: var(--size-20);
  flex-direction: column;

  @media (--viewport-md) {
    gap: var(--size-40);
    flex-direction: row;
  }
}
