.component {
  position: relative;

  & > .logoLayout {
    position: absolute;
    top: var(--size-50);
    left: 0;
    right: 0;
    z-index: 1; /** TODO: Oplossing zoeken voor stacking issue */
  }

  & > .framesLayout {
    height: 100%;
    width: 100%;
  }

  & > .containerLayout {
    position: absolute;
    bottom: var(--size-100);
    right: 0;
    left: 0;
    z-index: 1; /** TODO: Oplossing zoeken voor stacking issue */
  }
}

.text {
  & > .logoLayout {
    width: 100%;
    max-width: 180px;
    margin-bottom: var(--size-10);

    @media (--viewport-md) {
      max-width: 280px;
    }
  }

  & > .headingLayout {
    max-width: 100%;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--size-30);
  align-items: center;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.logoContainer {
  & > .logoComponentLayout {
    width: max-content;
  }
}
