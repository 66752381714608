.componentBase {
  font-family: var(--primary-font-family);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-md);
  line-height: var(--line-height-md);
  text-wrap: balance;
}

.componentPrimaryLg,
.componentSecondaryLg {
  line-height: var(--line-height-md);
  font-size: var(--font-size-20-36);
}

.componentPrimaryMd,
.componentSecondaryMd {
  line-height: var(--line-height-lg);
  font-size: var(--font-size-16-20);
}

.componentPrimaryAlternateMd {
  line-height: var(--line-height-lg);
  font-size: var(--font-size-16-20);
  font-family: var(--font-family-heading);
}

.componentPrimaryAlternateSm {
  line-height: var(--line-height-lg);
  font-size: var(--font-size-16-12);
  font-family: var(--font-family-heading);
}

.componentPrimarySm,
.componentSecondarySm {
  line-height: var(--line-height-lg);
  font-size: var(--font-size-16-12);
}

.primary {
  color: var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
}
