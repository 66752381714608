.component {
  --gap: var(--size-10);

  gap: 0 var(--gap);
  display: flex;
  align-items: center;
  color: var(--primary-color);

  & > .offset {
    margin-left: calc(22px + var(--gap));
  }
}

.text {
  font-size: var(--font-size-12);
  font-family: var(--font-family-work-sans);
}

.icon {
  line-height: 0;
}
