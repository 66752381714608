.component {
  display: flex;
  justify-content: center;
  padding: var(--container-padding);
  contain: layout paint;
  overflow-x: visible;
  position: relative;
  cursor: none;
  z-index: 0;

  & > * {
    max-width: var(--container-max-width-lg);
  }

  & > .cursorLayout {
    position: absolute;
    height: var(--scale);
    width: var(--scale);
    z-index: 1;
    left: 0;
    top: 0;
  }
}

.slider {
  --width: 350px;

  overflow: visible !important;

  @media (--viewport-md) {
    --width: 400px;
  }

  & > .cardLayout {
    min-width: min(400px, 65vw);
    max-height: 65dvh;
    height: 100%;
  }
}

.componentCard {
  --card-background: var(--color-blue-400);

  display: grid;
  position: relative;
  align-content: space-between;
  color: var(--color-white);
  background-color: var(--card-background);
  padding: var(--size-30);
  aspect-ratio: 0.75;
  z-index: 0;

  &:nth-child(even) {
    --card-background: var(--color-blue-300);
  }

  & > .backgroundVisualLayout {
    position: absolute;
    height: 225%;
    width: 225%;
    left: var(--position-left);
    top: var(--position-top);
  }

  & > .backgroundImageLayout {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    inset: 0;
  }

  & > .textLayout {
    min-height: 2lh;
  }
}

.componentBackgroundImage {
  image-rendering: optimizeQuality;
  object-fit: cover;
}

.componentBackgroundVisual {
  pointer-events: none;
  user-select: none;
  opacity: 0.2;
}

.iconContainer {
  & > .iconLayout {
    height: 20px;
    width: 20px;
  }
}

.componentCursor {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-5);
  background: color-mix(in srgb, var(--color-white) var(--color-mix-value), var(--color-blue-300));
  overflow: clip;
  user-select: none;
  pointer-events: none;
  transform-origin: center;
  border-radius: 50%;
  line-height: 0;
}
