:root {
  --duration-150: 150ms;
  --duration-200: 200ms;
  --duration-250: 250ms;
  --duration-300: 300ms;
  --duration-400: 400ms;
  --duration-850: 850ms;
  --duration-1250: 1250ms;

  --ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
  --ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
}
