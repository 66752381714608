.componentBase {
  display: grid;
  position: relative;
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas:
    'counter'
    'title'
    'description';

  & > .headingLayout {
    margin-bottom: var(--size-15);
  }

  & > .textLayout {
    &:not(:first-of-type) {
      margin-top: var(--size-20);
    }
  }

  &::before {
    grid-area: counter;
    font-size: var(--font-size-16-12);
    line-height: var(--line-height-base);
    font-family: var(--primary-font-family);
    margin-bottom: var(--size-20);
    content: attr(data-count);
    color: currentColor;
  }
}
