.component {
  position: relative;

  & > .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.overlay {
  background: var(--color-black);
  opacity: 0.4;
}
