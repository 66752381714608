.component {
  position: relative;
  z-index: 0;

  & > .playerContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  & > .image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .playButtonLayout {
    transform: translate(50%, -50%);
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 50%;
    width: var(--size-60);
    height: var(--size-60);

    @media (--viewport-md) {
      width: var(--size-80);
      height: var(--size-80);
    }
  }
}

.playerContainer {
  position: relative;
  aspect-ratio: 16 / 9;

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.image {
  object-fit: cover;
}

.componentPlayButtonAndTitle {
  border: 1px solid var(--primary-accent-color);
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
