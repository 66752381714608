.main {
  position: relative;
  background-color: var(--primary-background-color);
  z-index: 0;

  & > .internationalStrategyLayout {
    overflow: hidden;
  }

  & > .figuresLayout {
    overflow: hidden;
  }

  & > .axiomLayout {
    margin-top: 80px;

    @media (--viewport-md) {
      margin-top: 180px;
    }
  }
}

.componentAxiom {
  display: grid;
  gap: var(--size-35);
  justify-items: center;
  text-align: center;
}

.heroGrid {
  padding-top: var(--size-125);
  display: grid;
  grid-template-areas: "title" "text";
  grid-template-columns: 1fr;
  gap: var(--size-60);

  @media (--viewport-md) {
    padding-top: var(--size-200);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title title" "... text";
  }

  & > .headingLayout {
    grid-area: title;
  }

  & > .textLayout {
    grid-area: text;
  }
}

.componentFigures {
  color: var(--color-blue-400);
}
