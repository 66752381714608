.componentBase {
  background-color: var(--primary-color);
  color: var(--primary-background-color);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  padding: var(--size-10) var(--size-30);
  border-radius: var(--size-8);
}

.componentLinkBase {
  --offset: 10px;
  --active-offset: 0px;
  --active-color: var(--primary-accent-color);
  --thickness: 2px;

  font-size: var(--font-size-16-20);
  line-height: var(--line-height-base);
  letter-spacing: var(--letter-spacing-lg);
  font-family: var(--primary-font-family);
  font-weight: var(--font-weight-400);
  text-decoration: none;
  border-radius: var(--offset);
  padding: var(--offset) 0;
  position: relative;
  transition: var(--duration-150);

  &::after {
    content: '';
    position: absolute;
    left: calc(var(--offset) * -1);
    right: calc(var(--offset) * -1);
    bottom: 0;
    top: 0;
  }

  &::before {
    content: "";
    left: 0;
    right: 0;
    position: absolute;
    height: var(--thickness);
    bottom: calc(calc(var(--offset) / 2) - var(--active-offset));
    background-color: var(--active-color);
    animation: in var(--duration-300) ease forwards;
    transition: bottom var(--duration-150) ease;
    transform-origin: left;
  }

  &:not(:focus):hover::before {
    transform-origin: right;
    animation: out var(--duration-300) ease forwards;
  }

  &:not(:hover):focus {
    --active-offset: calc(var(--offset) / 2);
  }

  &:focus {
    --active-color: transparent;

    outline-color: var(--primary-accent-color);

    &:hover {
      outline-color: color-mix(in srgb, var(--primary-accent-color) 50%, var(--primary-background-color));
    }
  }
}

.componentLinkPrimary {
  color: var(--primary-color);
}

.componentLinkSecondary {
  color: var(--secondary-color);
}

@keyframes in {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

@keyframes out {
  from { clip-path: rect(0 100% var(--thickness) 0%); }
  to { clip-path: rect(0 100% var(--thickness) 100%); }
}
