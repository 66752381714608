.component {
  --fallback-display-mode: none;

  display: grid;
  position: relative;
  z-index: 0;
  aspect-ratio: 9 / 16;

  @media (--viewport-md) {
    aspect-ratio: 16 / 9;
  }

  & > .fallbackBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  & > .video {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    grid-area: 1 / -1;
  }
}

.video {
  object-fit: cover;
}

.fallbackBackground {
  display: var(--fallback-display-mode);
  background-color: var(--color-blue-400);

  & > * {
    height: 100%;
  }

  &.isSuspended {
    --fallback-display-mode: block;
  }
}
