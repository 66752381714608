.component {
  display: grid;

  & > .matchingPartnerLayout {
    width: 100%;
    margin-top: var(--size-25);
  }
}

.logos {
  --gap: var(--size-30);

  display: grid;
  align-items: center;
  grid-template-columns: calc(60px + var(--gap)) 2px calc(60px + var(--gap));

  @media (--viewport-sm) {
    grid-template-columns: calc(80px + var(--gap)) 2px calc(80px + var(--gap));
  }

  @media (--viewport-md) {
    grid-template-columns: calc(100px + var(--gap)) 2px calc(100px + var(--gap));
  }

  & > .aramcoLayout,
  & > .circle8Layout {
    margin: var(--size-10) 0;
  }

  & > .dividerLayout {
    height: 100%;
  }
}

.componentDivider {
  background-color: var(--primary-color);
}

.componentAsset {
  color: var(--primary-color);
  text-decoration: none;
  overflow: clip;

  &.left {
    padding-right: var(--size-30);
  }

  &.right {
    padding-left: var(--size-30);
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.logo {
  display: grid;
  place-items: center;
}
