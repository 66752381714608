.main {
  position: relative;
  background-color: var(--primary-background-color);
  z-index: 0;

  & > .heroLayout {
    min-height: 100dvh;
    overflow: hidden;
  }
}

.componentHero {
  display: grid;
  padding-top: var(--size-125);
  grid-template-rows: 120px minmax(0, 1fr) [typography] max-content minmax(0, 1fr) 120px;
  grid-template-columns: 30px 1fr var(--size-50);
  grid-template-areas:
    'typography   typography   ...'
    '...          ...   ...'
    '...          ...   ...';

  @media (--viewport-md) {
    padding-top: var(--size-200);
    grid-template-rows: 60px minmax(0, 1fr) [typography] max-content minmax(0, 1fr) 60px;
    grid-template-columns: 2fr 140px 1fr;
    grid-template-areas:
      'typography   ...   ...'
      '...          ...   ...'
      '...          ...   ...';
  }

  & > .typography {
    grid-area: typography;
  }

  & > .visualLayout {
    grid-row: 1 / -1;
    grid-column: 3 / -1;
  }
}

.typography {
  display: grid;
  grid-auto-flow: row;
  row-gap: var(--size-20);

  & > .headingLayout {
    margin-bottom: var(--size-10);
  }

  & > .subheadingLayout {
    margin-bottom: var(--size-30);
  }
}

.componentVisual {
  position: relative;
  color: var(--primary-background-color);
  transform: translateY(-30%);

  @media not (--viewport-lg) {
    display: none;
  }

  & > * {
    position: absolute;
    height: 110%;
  }
}
