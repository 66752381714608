.component {
  --offset: 10px;
  --active-offset: 0;
  --active-color: var(--primary-accent-color);
  --thickness: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: var(--offset);
  padding: var(--offset);

  & > :not(:first-child) {
    margin-top: calc(-1 * var(--size-15));
  }

  &:focus {
    --active-color: transparen;

    outline-color: var(--primary-accent-color);

    &:hover {
      outline-color: color-mix(in srgb, var(--primary-accent-color) 50%, var(--primary-background-color));
    }
  }
}

.arrow {
  color: var(--primary-accent-color);
  animation: arrowOpacity 1.5s infinite;
}

.arrowSecond {
  animation-delay: 0.25s;
}

.arrowThree {
  animation-delay: 0.5s;
}

@keyframes arrowOpacity {
  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.6;
  }
}
