
.component {
  position: relative;
  padding: 100px 0;
  z-index: 0;

  @media (--viewport-md) {
    padding: 180px 0;
  }
}

.figuresInner {
  display: grid;
  row-gap: var(--size-40);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    'a'
    'b'
    'c'
    'd'
    'e'
    'f';

  @media (--viewport-md) {
    row-gap: var(--size-20);
    column-gap: var(--size-40);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-template-areas:
      'a    ...'
      '...  b'
      'c    ...'
      '...  d'
      'e    ...';
  }

  & > *:nth-child(1) { grid-area: a; }
  & > *:nth-child(2) { grid-area: b; }
  & > *:nth-child(3) { grid-area: c; }
  & > *:nth-child(4) { grid-area: d; }

  & > *:nth-child(5) {
    grid-area: e;
    height: fit-content;
    width: fit-content;
  }
}

.componentFigure {
  display: grid;
  gap: var(--size-10);
}

.componentDigit {
  color: var(--primary-color);
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  letter-spacing: var(--letter-spacing-lg);
  font-size: var(--font-size-42);
  line-height: 0.75;

  @media (--viewport-md) {
    font-size: var(--font-size-80);
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-100-160);
  }

  & > .hiddenOnDesktop {
    @media (--viewport-lg) {
      display: none;
    }
  }
}
