.component {
  display: flex;
  justify-content: center;
  padding-left: var(--size-50);
  padding-right: var(--size-50);
  padding-bottom: var(--size-80);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding-left: var(--container-x-padding);
    padding-right: var(--container-x-padding);
  }

  & > .inner {
    width: 100%;
    max-width: var(--container-max-width-lg);
  }
}

.inner {
  display: grid;
  justify-content: space-between;
  gap: var(--size-20) 0;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'links'
    'privacy';

  @media (--viewport-md) {
    grid-template-columns: auto auto auto;
    gap: var(--size-40) 0;
    grid-template-areas:
      'logo ... links'
      'privacy ... links';
  }

  & > .logo {
    grid-area: logo;
    max-width: 165px;

    @media (--viewport-md) {
      width: 300px;
    }
  }

  & > .links {
    grid-area: links;
  }

  & > .privacy {
    grid-area: privacy;
  }
}

.logo {
  font-size: 0;
  color: var(--primary-color);
}

.mailToLink {
  text-decoration-color: var(--primary-color);
}

.links {
  font-family: var(--font-family-alternate);

  & > .content {
    margin-top: var(--size-10);
  }
}

.title {
  color: var(--primary-color);
  font-weight: var(--font-weight-600);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-40);
  }
}

.contactDetails {
  text-decoration: none;
}

.address,
.contactDetails {
  & > *:not(:first-child) {
    margin-top: var(--size-8);
  }
}

.componentLink,
.componentAnchor {
  --offset: 10px;

  display: block;
  position: relative;
  color: var(--primary-color);
  font-family: var(--font-family-alternate);
  font-size: var(--font-size-16);
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 5px;
  transition: 150ms ease-in-out;

  &:hover {
    text-decoration-color: var(--primary-accent-color);
    text-underline-offset: 3px;
  }

  &:focus,
  &:active {
    outline-color: var(--primary-accent-color);
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(var(--offset) * -1);
    right: calc(var(--offset) * -1);
    bottom: 0;
    top: 0;
  }
}
