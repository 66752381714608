.main {
  position: relative;
  background-color: var(--primary-background-color);
  z-index: 0;

  & > .articleLayout {
    min-height: 100dvh;
    overflow: hidden;
  }
}

.componentArticle {
  display: grid;
  padding-top: var(--size-125);
  grid-template-rows: max-content auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'typography'
    'link';

  @media (--viewport-md) {
    padding-top: 0;
    grid-template-rows: 200px 680px max-content auto;
    grid-template-columns: 2fr 140px 1fr;
    grid-template-areas:
      '...          ...   visual'
      'typography   ...   visual'
      'typography   ...   ...'
      'link          ...   ...';
  }

  & > .typography {
    grid-area: typography;
  }

  & > .visualLayout {
    grid-area: visual;
  }

  & > .link {
    margin-top: var(--size-40);
    grid-area: link;
  }
}

.typography {
  display: grid;
  grid-auto-flow: row;
  row-gap: var(--size-5);

  & > .headingLayout {
    margin-bottom: var(--size-10);
  }

  & > .subheadingLayout {
    margin-bottom: var(--size-30);
  }

  & > .paragraphHeadingLayout {
    margin-top: var(--size-30);
    margin-bottom: var(--size-5);
  }

  & > .imageLayout {
    width: 100%;
    margin-top: var(--size-10);
    margin-bottom: var(--size-40);
  }
}

.ctaContainer {
  display: flex;
  gap: var(--size-40);
}

.link {
  display: flex;
  justify-content: flex-end;
}

.componentVisual {
  position: relative;
  color: var(--primary-background-color);
  transform: translateY(-30%);

  @media not (--viewport-md) {
    display: none;
  }

  & > * {
    position: absolute;
    height: 110%;
  }
}
