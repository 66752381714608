.main {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > .framesLayout {
    height: 100dvh;
  }

  & > .progressLayout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 2px;
  }

  & > .videoLayout,
  & > .videoWithBackgroundLayout,
  & > .imageQuoteLayout {
    height: 100dvh;
  }

  & > .backgroundImageLayout {
    min-height: 100dvh;

    @media (--viewport-lg) {
      min-height: unset;
      height: 100dvh;
    }
  }

  & > .videoWithBackgroundLayout {
    height: 80dvh;

    @media (--viewport-lg) {
      height: 100dvh;
    }
  }

  & > .introductionLayout {
    @media (--viewport-lg) {
      margin-bottom: 180px;
      margin-top: 180px;
    }
  }
}
