.component {
  display: flex;
  justify-content: center;
  padding: var(--size-20);
}

.content {
  display: flex;
  align-items: center;
  gap: var(--size-30);
  padding: var(--size-15) var(--size-25);
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  border-radius: var(--size-8);

  & > .readMoreButtonLayout,
  .confirmationButtonLayout {
    flex-shrink: 0;
  }
}
