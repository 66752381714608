:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f8f8f8;
  --color-green-300: #1e4c46;
  --color-green-400: #0f2a27;
  --color-green-800: #12201e;
  --color-yellow: #faff17;
  --color-red: #f00;
  --color-blue-300: #112d63;
  --color-blue-400: #02214d;

  --color-yellow--alpha-10: rgba(251, 255, 23, 0.1);
  --color-red--alpha-10: rgba(255, 0, 0, 0.1);
  --color-red--alpha-30: rgba(255, 0, 0, 0.3);
  --color-black--alpha-05: rgba(0, 0, 0, 0.05);
  --color-black--alpha-10: rgba(0, 0, 0, 0.1);
  --color-black--alpha-20: rgba(0, 0, 0, 0.2);
  --color-white--alpha-20: rgba(255, 255, 255, 0.2);
  --color-white--alpha-30: rgba(255, 255, 255, 0.3);
  --color-black-box-shadow: 198px 74px 85px rgba(0, 0, 0, 0.01), 111px 42px 71px var(--color-black--alpha-05), 50px 19px 53px var(--color-black--alpha-05), inset 2px 1px 2px #fff;
}
