.component {
  z-index: 0;
  position: relative;

  & > .footer {
    z-index: 0;
    min-height: 25dvh;
  }
}

.visualWrapper {
  color: var(--primary-background-color);
}

.componentBase {
  position: relative;
  z-index: 0;

  & > .floatingContent {
    z-index: 1;
  }

  & > .stickyMenuBarLayout {
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 0;
  }

  & > .progressBarLayout {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 2px;
    top: 0;
  }
}

.floatingContent {
  position: relative;
}

.componentFooter {
  position: relative;
  z-index: 0;
  padding-top: 250px;
  padding-bottom: 100px;
  background-color: var(--primary-background-color);
  overflow: hidden;

  & > .visualWrapper {
    z-index: -1;
    position: absolute;
    right: -200px;
    top: 150px;
    width: 800px;
  }
}

.componentCollabFooter {
  background-color: var(--primary-background-color);
  padding-top: var(--size-40);
  position: relative;
  overflow: hidden;

  @media (--viewport-md) {
    padding-top: var(--size-125);
  }

  & > .visualLayout {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -200px;
    top: 30vh;

    @media (--viewport-lg) {
      width: 900px;
      height: 900px;
      top: unset;
      bottom: 25%;
      left: -5%;
    }

    @media (--viewport-xl) {
      width: 1200px;
      height: 1200px;
    }
  }

  & > .brandsLayout {
    margin-top: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-200);
    }
  }

  & > .emailFormLayout {
    margin-top: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-250);
    }
  }

  & > .footerLinksLayout {
    margin-top: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-400);
    }
  }

  & > .finishLine {
    height: var(--size-45);
    width: 100%;
  }
}

.finishLine {
  background-image: url('/images/collab/finish-line.png');
}
