.component {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: start;
  justify-content: space-evenly;
  align-items: center;
  padding: calc(var(--size-20) + 2px) var(--container-x-padding) var(--size-20);
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  box-shadow: 0 0 30px 0 var(--color-black--alpha-20);

  & > .inner {
    width: 100%;
  }
}

.inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  justify-items: start;
  justify-content: space-between;
  align-items: center;

  & > .logoLayout {
    height: auto;
    width: 80px;
  }
}

.componentMenuDesktop {
  display: flex;
  align-items: center;
  gap: var(--size-20);
}

.componentMenuMobile {
  position: relative;

  & > .hamburgerLayout {
    width: var(--size-15);
    height: var(--size-15);
  }
}

.overlay {
  pointer-events: none;
  transform: translateY(-100%);
  transition: transform var(--duration-400) var(--ease-out-quad);

  &.isActive {
    pointer-events: auto;
    transform: translateY(0);
  }

  & > .menuMobileList {
    width: 100vw;
    max-width: 100%;
    height: 100lvh;
    max-height: 100%;
    left: 0;
    top: 0;
  }
}

.menuMobileList {
  display: flex;
  flex-direction: column;
  gap: var(--size-25);
  padding: var(--size-125) var(--container-x-padding);
  background-color: var(--primary-background-color);
}

.componentLink {
  position: relative;
  color: var(--primary-color);
  font-family: var(--font-family-work-sans);
  text-decoration: none;

  @media (--viewport-lg) {
    font-size: var(--font-size-14-16);
  }

  &::before {
    content: '';
    @media (--viewport-md) {
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -5px;
      background-color: var(--primary-accent-color);
      transition: bottom var(--duration-150) ease;
      transform-origin: right;
      animation: hamburger-animate-out var(--duration-300) ease forwards;
      pointer-events: none;
    }
  }

  &:hover::before {
    @media (--viewport-md) {
      transform-origin: left;
      animation: hamburger-animate-in var(--duration-300) ease forwards;
    }
  }
}

.componentLinkDesktop {
  --offset: 10px;

  position: relative;
  padding: calc(var(--offset) / 2) var(--offset);
  border-radius: var(--offset);
  font-size: var(--font-size-16);

  &:focus {
    outline-color: var(--primary-accent-color);

    &:hover {
      outline-color: color-mix(in srgb, var(--primary-accent-color) 50%, var(--primary-background-color));
    }
  }
}

.componentLinkMobile {
  font-size: var(--font-size-20-25);
}

.componentHamburger {
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: var(--size-12);

  & > .hamburger {
    width: var(--size-15);
    height: 2px;
    right: 0;
  }
}

.hamburger {
  position: relative;
  background-color: var(--primary-color);
  transition: var(--duration-400) var(--ease-in-out-circ);
  transform: translateY(1px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: var(--size-15);
    height: 2px;
    left: 0;
    background-color: var(--primary-color);
    transition: var(--duration-400) var(--ease-in-out-circ);
  }

  &::before {
    transform: translateY(-6px);
  }

  &::after {
    transform: translateY(6px);
  }

  &.isActive {
    background-color: transparent;
    transition: var(--duration-250) var(--ease-in-out-circ);

    &::before {
      transform: translateY(0);
      rotate: -45deg;
    }

    &::after {
      transform: translateY(0);
      rotate: 45deg;
    }
  }
}

.componentLogo {
  display: inline-flex;
  color: var(--primary-color);

  & > * {
    height: 100%;
    width: 100%;
  }
}

.logoElement {
  display: inline-grid;
}

@keyframes hamburger-animate-in {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

@keyframes hamburger-animate-out {
  from { clip-path: rect(0 100% 2px 0%); }
  to { clip-path: rect(0 100% 2px 100%); }
}
