.component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--size-50);

  @media (--viewport-md) {
    flex-direction: row;
    gap: 0;
  }

  & > .contentLayout {
    width: 100%;

    @media (--viewport-md) {
      width: 35%;
    }

    @media (--viewport-lg) {
      width: 25%;
    }
  }

  & > .circleLayout {
    order: -1;
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.componentCircle {
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .background {
    min-height: 250px;
    min-width: 250px;

    @media (--viewport-sm) {
      min-width: 300px;
      min-height: 300px;
    }

    @media (--viewport-md) {
      min-width: 400px;
      min-height: 400px;
    }

    @media (--viewport-lg) {
      min-width: 500px;
      min-height: 500px;
    }

    @media (--viewport-xl) {
      min-width: 600px;
      min-height: 600px;
    }
  }

  & > .titles {
    z-index: 1;
    position: absolute;
    max-width: 250px;

    @media (--viewport-md) {
      max-width: 400px;
    }
  }
}

.titles {
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentContent {
  color: var(--primary-color);
  font-size: var(--font-size-16);
  font-family: var(--font-family-work-sans);

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-50);
    }
  }
}

.background {
  background-color: var(--primary-background-color);
  box-shadow: var(--color-black-box-shadow);
  border-radius: 50%;
}

.text,
.headingContainer {
  transition: opacity var(--duration-200) var(--ease-out-quad);
}
