.component {
  & > .inner {
    @media (--viewport-lg) {
      width: 80%;
    }
  }
}

.inner {
  & > .textLayout {
    margin-top: var(--size-20);
  }
}
