@value _dark: dark;
@value _light: light;

:export {
  contextDark: _dark;
  contextLight: _light;
}

[data-typography-context='_dark'] {
  --primary-font-family: var(--font-family-work-sans);
  --font-family-alternate: var(--font-family-avenir);
  --font-family-heading: var(--font-family-optima);
  --font-family-footer: var(--font-family-heading);

  --heading-primary-weight: var(--font-weight-400);
  --heading-secondary-weight: var(--font-weight-600);

  --font-weight-footer: var(--font-weight-400);
}

[data-typography-context='_light'] {
  --primary-font-family: var(--font-family-work-sans);
  --font-family-alternate: var(--font-family-avenir);
  --font-family-heading: var(--font-family-recife);
  --font-family-footer: var(--font-family-avenir);

  --heading-primary-weight: var(--font-weight-400);
  --heading-secondary-weight: var(--font-weight-600);

  --font-weight-footer: var(--font-weight-600);
}
