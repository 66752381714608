.componentBase {
  & > .headingLayout {
    margin-bottom: var(--size-30);
    max-width: 42ch;
  }

  & > .textLayout {
    margin-bottom: var(--size-50);
  }
}
