:root {
  --size-5: 5px;
  --size-8: 8px;
  --size-10: 10px;
  --size-15: 15px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-35: 35px;
  --size-40: 40px;
  --size-45: 45px;
  --size-50: 50px;
  --size-60: 60px;
  --size-80: 80px;
  --size-100: 100px;
  --size-125: 125px;
  --size-200: 200px;
  --size-250: 250px;
  --size-400: 400px;

  --container-sm: 480px;
  --container-md: 768px;
  --container-lg: 1024px;

  --container-max-width-md: 800px;
}
