.componentBase {
  position: relative;
  padding: var(--size-100) 0;
  overflow: clip;
  display: grid;
  z-index: 0;

  & > .image {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 120%;
  }

  & > .container {
    height: 100%;
    z-index: 3;
    position: relative;
    margin: auto;
  }

  & > .containerMd {
    max-width: var(--container-max-width-md);
  }

  & > .containerLg {
    max-width: var(--container-max-width-lg);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 33%;
    background: linear-gradient(180deg, #12201e 0%, rgba(0, 0, 0, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 2;

    @media (--viewport-md) {
      height: 25%;
    }
  }
}

.container {
  padding: 0 var(--size-30);

  & > .inner {
    height: 100%;
  }
}

.inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  align-items: center;

  & > .contentLayout {
    @media (--viewport-md) {
      margin-left: 0.45em;
    }
  }

  &.isLeftAligned {
    & > .ctaLayout {
      justify-self: flex-start;
    }
  }

  &:not(.isLeftAligned) {
    & > .ctaLayout {
      justify-self: flex-end;
    }
  }

  & > .ctaLayout {
    width: fit-content;
  }
}

.image {
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
}

.quote {
  letter-spacing: var(--letter-spacing-lg);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-42-70);
  line-height: var(--line-height-base);
  color: var(--primary-color);
  text-indent: -0.45em;
}
