.componentLg {
  & > .inner {
    max-width: var(--container-max-width-lg);
  }
}

.componentMd {
  & > .inner {
    max-width: var(--container-max-width-md);
  }
}

.componentBase {
  display: grid;
  padding: var(--container-padding);
  position: relative;

  & > .inner {
    width: 100%;
    margin: 0 auto;
    max-height: inherit;
  }
}

.inner {
  & > * {
    height: 100%;
    max-height: inherit;
  }
}
