.component {
  position: relative;

  & > .container {
    max-width: var(--container-max-width-lg);
    margin: auto;
    height: 100%;
  }
}

.container {
  z-index: 0;
  position: relative;
  padding: var(--size-80) var(--size-25);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'content'
    '...';

  @media (--viewport-lg) {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 20% 370px auto;
    grid-template-areas:
      '... ...'
      '... content'
      '... content';
  }

  & > .backgroundLayout {
    z-index: 0;
    align-self: end;
    justify-self: start;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 130%;

    @media (--viewport-lg) {
      position: absolute;
      grid-column: 1 / -1;
      grid-row: 1 / -2;
      width: 1200px;
    }
  }

  & > .inner {
    grid-area: content;
    z-index: 1;
  }
}

.inner {
  & > .headingLayout {
    margin-bottom: var(--size-30);
  }

  & > .textLayout {
    margin-bottom: var(--size-20);
  }
}

.componentVisual {
  transform: translateX(-15%) translateY(-10%);

  @media (--viewport-lg) {
    transform: translateX(-35%) translateY(15px);
  }

  & > .visualLayout {
    width: 100%;
    height: 100%;
  }
}
