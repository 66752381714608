.component {
  display: grid;
  align-items: start;
  justify-content: space-evenly;
  row-gap: 95px; /** TODO */
  column-gap: 160px; /** TODO */
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  list-style: none;
  padding: 0;
  color: var(--primary-color);

  @media (--viewport-md) {
    grid-template-columns: minmax(0, 400px) minmax(0, 400px);
    grid-template-rows: max-content max-content;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}
