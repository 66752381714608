.pageContainer {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .containerLayout {
    z-index: 0;
    min-height: 100vh;
    height: 100%;
  }
}

.logoWrapper {
  position: relative;
  z-index: 0;
  color: var(--color-white);

  & > .pageLogoLayout {
    z-index: -1;
    position: absolute;
    top: -25px;
    right: -50px;
    width: 220px;

    @media (--viewport-md) {
      top: -50px;
      right: -50px;
      width: 500px;
    }
  }
}

.page {
  padding: var(--size-125) 0 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-60);

  @media (--viewport-md) {
    gap: var(--size-125);
    padding: var(--size-200) 0;
  }
}

.artilesContainer {
  display: flex;
  flex-direction: column;
}

.article {
  --index: 0;

  display: grid;
  grid-template-rows: [content] 1fr [line] 1px;
  gap: var(--size-40);
  position: relative;

  & > .titleTextAndCTALgLayout {
    grid-row: content;
  }

  &:not(:first-of-type) {
    padding-top: var(--size-60);
  }

  &:not(:last-child) {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      grid-row: line;
      position: absolute;
      transform: scaleX(0);
      transform-origin: left;
      background-color: var(--color-blue-400);
      animation: newsroom-line-reveal var(--duration-850) ease forwards;
      animation-delay: calc(var(--duration-1250) + var(--duration-200) * var(--index));
    }
  }
}

.main {
  z-index: 0;
  background-color: var(--primary-background-color);
  position: relative;

  & > .progressBarLayout {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 2px;
    top: 0;
  }

  & > .stickyMenuBarLayout {
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 0;
  }
}

@keyframes newsroom-line-reveal {
  from {
    opacity: 0;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
