.page {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .backgroundVideoLayout {
    position: fixed;
    top: 0;
    max-height: 100dvh;
    min-height: 100vh;
    max-width: 100dvw;
    min-width: 100vw;
    height: 100%;
  }

  & > .heroLayout {
    z-index: 1;
    min-height: 100vh;
    height: 100%;
  }

  & > .visual {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 550px;
  }
}

.visual {
  opacity: 0;

  @media (--viewport-lg) {
    opacity: 1;
  }
}

.background {
  position: relative;
  z-index: 0;
  background-color: var(--primary-background-color);
  padding-top: var(--size-80);

  & > .introductionLayout {
    z-index: 0;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  & > .companyVisionLayout {
    z-index: 0;
    margin-top: 50px;
    margin-bottom: 10px;

    @media (--viewport-md) {
      margin-top: 145px;
      margin-bottom: 150px;
    }
  }

  & > .imageQuoteLayout {
    min-height: 80dvh;
  }

  & > .ourBrandsLayout,
  & > .internationalStrategyWithoutVisualLayout {
    z-index: 0;
    margin-top: 100px;
    margin-bottom: 50px;
    /* stylelint-disable-next-line kaliber/layout-related-properties */
    scroll-margin: 100px;
  }

  & > .brandsLayout {
    z-index: 0;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
