:root {
  --container-sm: 480px;
  --container-md: 768px;
  --container-lg: 1024px;
  --container-xl: 1200px;

  --container-max-width-md: 800px;
  --container-max-width-lg: 1200px;

  --container-x-padding: min(5vw, calc(2.5vw + 24px));
  --container-padding: 0 var(--container-x-padding);
}
