.component {
  --heading-max-width: 14ch;
  --content-alignment: left;

  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    'content'
    'map';

  @media (--viewport-lg) {
    grid-template-columns: var(--calculated-padding) 3fr 4fr;
    grid-template-areas: '... content map';
    padding: 0;
  }

  & > .contentLayout {
    margin-top: 50px;
    margin-bottom: 0;

    @media (--viewport-lg) {
      margin-top: 170px;
      margin-bottom: 60px;
    }
  }
}

.componentWithoutVisual {
  --heading-max-width: 42ch;
  --content-alignment: center;

  grid-template-columns: 1fr;
  grid-template-areas: 'content';
  text-align: center;

  @media (--viewport-lg) {
    grid-template-columns: var(--calculated-padding) 1fr var(--calculated-padding);
    grid-template-areas: '... content ...';
    padding: 0;
  }
}

.componentBase {
  --container-remainder: calc((100vw - var(--container-max-width-lg)) / 2);
  --calculated-padding: max(var(--container-remainder), var(--container-x-padding));

  display: grid;

  & > .contentLayout {
    grid-area: content;
  }

  & > .visualLayout {
    grid-area: map;

    @media (--viewport-lg) {
      min-height: 70dvh;
    }
  }
}

.componentContentBase {
  display: grid;
  justify-items: var(--content-alignment);
  align-content: center;
  padding: var(--container-padding);

  @media (--viewport-lg) {
    padding: 0;
  }

  & > .headingLayout {
    max-width: var(--heading-max-width);
    margin-bottom: var(--size-40);
  }

  & > .textLayout {
    margin-bottom: var(--size-40);
  }
}

.componentVisual {
  position: relative;

  & > .mapElement {
    height: auto;
    width: 100%;

    @media (--viewport-lg) {
      height: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(to left, var(--primary-background-color), transparent 35%);
    height: 100%;
    width: 100%;
    top: -5%;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    @media (--viewport-lg) {
      top: 0;
    }
  }
}

.mapElement {
  transform: translateX(25%) translateY(-5%);

  @media (--viewport-lg) {
    transform: translateX(25%);
  }
}
