.component {
  display: flex;
  padding-block: var(--size-40);
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  @media (--viewport-lg) {
    flex-direction: row;
  }

  @media (--viewport-xl) {
    flex-direction: row;
    gap: var(--size-20);
  }

  &:nth-child(even) {
    @media (--viewport-xl) {
      flex-direction: row-reverse;
    }
  }

  & > .smallFigureLayout {
    flex-shrink: 0;
  }
}

.componentSmallFigureWithText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-10);

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: center;
  }
}

.smallFigure {
  font-size: var(--font-size-70);
  font-weight: var(--font-weight-400);
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}

.text {
  font-family: var(--font-family-work-sans);
}

.componentLargeFigure {
  font-size: var(--font-size-100);
  line-height: var(--line-height-base);
  font-family: var(--font-family-recife);
  font-weight: var(--font-weight-400);
  letter-spacing: -0.03em;

  @media (--viewport-md) {
    font-size: 350px;
  }

  @media (--viewport-xl) {
    font-size: 600px;
  }
}
