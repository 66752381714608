.page {
  padding-top: var(--size-50);
  padding-bottom: var(--size-50);
}

.headerContent {
  display: flex;
  gap: var(--size-10);
  color: var(--primary-color);
  align-items: center;
  line-height: 0;

  & > .logoLayout {
    width: var(--size-100);
  }
}

.componentParagraphSection {
  & > .paragraphLayout {
    margin-top: var(--size-10);
  }
}

.content {
  padding-top: var(--size-50);
  padding-bottom: var(--size-50);

  & > .sectionLayout {
    margin-bottom: var(--size-50);
  }
}
