.component {
  position: relative;
  content-visibility: visible;
  contain-intrinsic-size: 1px 100dvh;
  contain: paint;
  z-index: 0;

  & > .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    right: 0;
    height: 470px;
    z-index: 1;
    background: linear-gradient(180deg, var(--color-green-800) 0%, rgba(0, 0, 0, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.frame {
  object-fit: cover;
  transform: scaleX(0);

  &[data-visible='true'] {
    transform: scaleX(1);
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentAVIF,
.componentWebP,
.componentImage {
  object-fit: cover;
}
