.componentBase {
  --line-height: var(--line-height-base);
  --alternate: var(--font-family-alternate);
}

.componentPrimaryXxl,
.componentSecondaryXxl {
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  line-height: var(--line-height-base);
  letter-spacing: var(--letter-spacing-lg);
  font-size: var(--font-size-35-75);
}

.componentPrimaryXl,
.componentSecondaryXl {
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing-md);
  font-size: var(--font-size-42-70);

  &:has(> .alternateFontElement) {
    --line-height: var(--line-height-xs);
  }

  & > .alternateFontElement {
    --alternate: var(--font-family-alternate);
  }
}

.componentPrimaryLg,
.componentSecondaryLg {
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing-sm);
  font-size: var(--font-size-25-35);
  text-wrap: balance;

  &:has(> .alternateFontElement) {
    --line-height: var(--line-height-xs);
  }

  & > .alternateFontElement {
    --alternate: var(--font-family-alternate);
  }
}

.componentPrimaryMd {
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing-sm);
  font-size: var(--font-size-20-36);
  text-wrap: balance;
}

.componentPrimarySm {
  font-family: var(--font-family-heading);
  font-weight: var(--heading-primary-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing-sm);
  font-size: var(--font-size-16-12);
  text-wrap: balance;

  &:has(> .alternateFontElement) {
    --line-height: var(--line-height-xs);
  }

  & > .alternateFontElement {
    --alternate: var(--font-family-alternate);
  }
}

.alternateFontElement {
  font-family: var(--alternate);
  font-weight: var(--heading-secondary-weight);
  letter-spacing: var(--letter-spacing-xl);
}

.primary {
  color: var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
}

.circle8FontElement {
  font-family: var(--font-family-circle8);
  line-height: inherit;
}

.superscriptElement {
  font-size: 0.65em;
  padding: 0 0.05em;
  line-height: 1;
}
