.component {
  position: relative;
  z-index: 0;
  display: grid;
  overflow: hidden;

  @media (--viewport-lg) {
    display: block;
  }

  & > .headingLeftLayout {
    position: absolute;
    left: 0;
    top: 10%;

    @media (--viewport-md) {
      top: 16%;
    }

    @media (--viewport-lg) {
      top: 13%;
    }
  }

  & > .headingRightLayout {
    position: absolute;
    right: 0;
    bottom: 10%;

    @media (--viewport-md) {
      bottom: 16%;
    }

    @media (--viewport-lg) {
      bottom: 13%;
    }
  }

  & > .container {
    max-width: var(--container-max-width-md);
    margin: auto;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
  }

  & > .visual {
    width: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    bottom: 50px;
    left: 0;
    right: 0;

    @media (--viewport-lg) {
      width: 1000px;
      bottom: 70px;
    }
  }
}

.componentAnimatedHeadingBase {
  font-size: var(--font-size-90-220);
  color: var(--primary-shade-color);
  font-weight: var(--font-weight-400);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-xl);
  line-height: var(--line-height-base);
}

.visual {
  transform: translateX(-70px);

  @media (--viewport-lg) {
    transform: translateX(-40px);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--size-30);
  position: relative;

  & > .videoLayout {
    width: 100%;
  }
}
