.main {
  position: relative;
  background-color: var(--primary-background-color);
  z-index: 0;
}

.header {
  & > .headingLayout {
    margin-top: var(--size-125);
    margin-bottom: 15px;

    @media (--viewport-md) {
      margin-top: var(--size-200);
    }
  }
}

.pageEngage,
.pageFind {
  display: flex;
  gap: var(--size-80);
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 200px;
}

.paragraph {
  display: inline-flex;
  flex-direction: column;
  gap: var(--size-10);
}

.componentAvailabilityList {
  display: flex;
  flex-direction: column;
}

.label {
  opacity: 0.3;
  font-size: var(--font-size-12);
  font-family: var(--primary-font-family);
}

.linkList {
  display: inline-flex;
  column-gap: var(--size-30);
  row-gap: var(--size-5);
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}
