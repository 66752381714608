.component {
  position: relative;
  z-index: 0;
  padding: var(--size-50);
  display: grid;

  @media (--viewport-lg) {
    padding: var(--size-50) 0;
    display: block;
  }

  & > .image {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    inset: 0;
  }

  & > .inner {
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: var(--container-max-width-lg);
  }
}

.inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: var(--size-50);
  grid-template-areas:
    'list'
    'button';

  @media (--viewport-lg) {
    padding: var(--container-padding);
    grid-template-rows: repeat(4, 1fr);
    gap: 0;
    grid-template-areas:
      '...'
      'list'
      '...'
      'button';
  }

  & > .list {
    width: 100%;
    grid-area: list;
  }

  & > .buttonLayout {
    justify-self: start;
    grid-area: button;
    align-self: end;

    @media (--viewport-lg) {
      justify-self: end;
    }
  }
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  gap: var(--size-50);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: var(--size-20);
  }

  & > * {
    @media (--viewport-lg) {
      max-width: 330px;
    }
  }
}

.image {
  opacity: 0.2;
  object-fit: cover;
}
