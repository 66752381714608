.componentImpl {
  @media (--viewport-lg) {
    display: flex;
    justify-content: flex-end;
  }

  & > .inner {
    @media (--viewport-lg) {
      width: 40%;
    }
  }
}

.inner {
  & > .textLayout {
    text-wrap: wrap;
    margin-top: var(--size-10);

    @media (--viewport-md) {
      margin-top: var(--size-20);
    }
  }
}

._rootformFieldText {
  background: none;
  border: none;
  border-bottom: 2px solid var(--color-yellow);
  display: block;
  width: 100%;
  font-size: var(--font-size-20);
  padding: var(--size-15) 0;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: var(--color-white--alpha-30);
  }
}

.fieldValidation {
  font-family: var(--primary-font-family);
  font-size: var(--font-size-16);
  color: var(--color-yellow);
}

._rootFieldMessages {
  margin-top: var(--size-5);
  position: relative;

  & > .fieldValidation {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.form {
  position: relative;

  & > .fieldLayout {
    margin-top: var(--size-30);
  }

  & > .submitButtonLayout {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.componentSubmitButton {
  --animation: unset;

  color: var(--color-white);
  padding: 18px;

  &:hover {
    --animation: toRightFromLeft 0.3s forwards;
  }
}

.icon {
  overflow: hidden;
  animation: var(--animation);
}

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }

  50% {
    opacity: 0;
    transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }
}
