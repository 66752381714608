.component {
  --card-height: max(50vh, 800px);

  @media (--viewport-md) {
    --card-height: 80vh;
  }

  scroll-snap-type: y mandatory;
  position: relative;
  z-index: 0;

  & > * {
    @media (--viewport-md) {
      max-height: 100%;
      height: var(--card-height);
    }
  }
}

.componentSection {
  --offset: 50px;

  @media (--viewport-md) {
    --offset: 50px;
  }

  position: sticky;
  scroll-snap-align: start;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  border-radius: var(--size-30);
  box-shadow: 0 -10px 50px rgba(0, 0, 0, 0.1), inset 2px 2px 2px #fff;

  &:first-of-type {
    @media (--viewport-md) {
      box-shadow: none;
      border-radius: unset;
      background-color: var(--primary-background-color);
    }
  }

  &:last-of-type {
    box-shadow: none;
    border-radius: var(--size-30) var(--size-30) 0 0;
  }

  & > .children {
    max-width: var(--container-xl);
    margin: auto;
  }
}

.componentContent {
  display: grid;
  row-gap: var(--size-20);
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'content-left'
    'content-right'
    'link';

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 85px 1fr auto;
    column-gap: var(--size-100);
    row-gap: 0;
    grid-template-areas:
      'content-left ...'
      'content-left content-right'
      '... link';
  }

  & > .listItemLayout {
    grid-area: content-left;
  }

  & > .contentRight {
    grid-area: content-right;
  }

  & > .link {
    grid-area: link;

    @media (--viewport-md) {
      margin-top: var(--size-20);
    }
  }
}

.contentRight {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);
  justify-content: space-between;

  @media (--viewport-md) {
    gap: var(--size-50);
  }

  & > .buttonLayout {
    align-self: flex-end;
  }
}

.link {
  display: flex;
  justify-content: flex-end;
}

.children {
  padding: var(--size-30) var(--size-20) var(--size-30) var(--size-20);

  @media (--viewport-md) {
    padding: var(--size-80);
  }
}

.visual {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentList {
  padding: 1em;
  list-style-type: disc;

  @media (--viewport-md) {
    padding: 0;
  }
}

.listItem {
  font-size: smaller;
}
