@value _dark: dark;
@value _light: light;

:export {
  contextDark: _dark;
  contextLight: _light;
}

[data-style-context='_light'] {
  color-scheme: light;

  --primary-background-color: var(--color-gray-100);
  --primary-color: var(--color-blue-300);
  --primary-shade-color: var(--color-blue-400);
  --primary-accent-color: var(--color-red);

  --secondary-color: var(--color-white);
  --secondary-background-color: var(--color-red--alpha-10);

  --primary-footer-logo-color: var(--primary-color);
  --secondary-footer-logo-color: var(--primary-accent-color);
}

[data-style-context='_dark'] {
  color-scheme: dark;

  --primary-background-color: var(--color-blue-400);
  --primary-color: var(--color-white);
  --primary-shade-color: var(--color-blue-300);
  --primary-accent-color: var(--color-red);

  --secondary-color: var(--color-green-800);
  --secondary-background-color: var(--color-white--alpha-20);

  --primary-footer-logo-color: var(--primary-color);
  --secondary-footer-logo-color: var(--primary-color);
}

[data-style-context='_dark'][data-domain='formula1'] {
  color-scheme: dark;

  --primary-background-color: var(--color-green-800);
  --primary-color: var(--color-white);
  --primary-shade-color: var(--color-green-300);
  --primary-accent-color: var(--color-yellow);

  --secondary-color: var(--color-green-800);
  --secondary-background-color: var(--color-yellow--alpha-10);

  --primary-footer-logo-color: var(--primary-color);
  --secondary-footer-logo-color: var(--primary-color);
}
