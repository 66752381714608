.component {
  display: grid;
  text-align: center;
  justify-content: center;

  & > .headingLayout {
    margin-bottom: var(--size-25);
  }

  & > .textLayout {
    max-width: min(880px, calc(100vw - (var(--container-x-padding) * 2)));
  }
}
