.page {
  min-height: 100dvh;
  background-color: var(--color-green-800);
  padding: var(--size-40) 0 0;
  background-image:
    linear-gradient(180deg, var(--color-black--alpha-20) 100px, #12201e 200px),
    url('/images/poster.jpg');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;

  @media (--viewport-lg) {
    background-image:
      linear-gradient(180deg, var(--color-black--alpha-20) 400px, #12201e 500px),
      url('/images/poster.jpg');
  }
}

.inner {
  & > .logoLayout {
    width: max-content;
  }
}

.componentTextWithVisual {
  position: relative;
  padding: var(--size-60) 0;

  @media (--viewport-lg) {
    padding: var(--size-100) var(--size-25);
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 150px 370px auto;
    grid-template-areas:
      '... ...'
      '... content'
      '... content';
  }

  & > .content {
    @media (--viewport-lg) {
      grid-area: content;
    }
  }

  & > .visualLayout {
    position: absolute;
    align-self: end;
    justify-self: start;
    width: 1200px;
    top: -700px;
    left: -40px;
  }
}

.content {
  & > .headingLayout,
  .textLayout {
    margin-bottom: var(--size-30);
  }

  & > .headingLayout {
    margin-bottom: var(--size-10);
  }

  & > .subheadingLayout {
    margin-bottom: var(--size-30);
  }

  & > .paragraphHeadingLayout {
    margin-top: var(--size-30);
    margin-bottom: var(--size-5);
  }

  & > .imageLayout {
    width: 100%;
    margin-top: var(--size-10);
    margin-bottom: var(--size-40);
  }
}

.buttonsContainer {
  display: inline-flex;
  gap: var(--size-20);
  flex-direction: column;

  @media (--viewport-lg) {
    gap: var(--size-60);
    flex-direction: row;
  }
}

.componentVisual {
  display: none;
  pointer-events: none;

  @media (--viewport-lg) {
    transform: translateX(-25%) translateY(-2%);
    display: block;
  }
}
