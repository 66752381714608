.component {
  scroll-margin-top: 400px;

  & > .brandLogosLayout {
    margin-bottom: 50px;
  }
}

.componentBrandLogos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-50);

  @media (--viewport-md) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--size-20);
    justify-content: space-between;
  }

  & > .link {
    flex-shrink: 1;
    width: 100%;
    max-width: 150px;
  }
}

.link {
  color: var(--primary-color);
}
